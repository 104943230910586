import React from 'react';
import { AppBadge } from '@launchpad';
import { toHumanString } from '@launchpad/util/StringHelper';

const CardStatusLabel = (props: { status: string }): JSX.Element | null => {
  const { status } = props;

  if (!status) return null;

  switch (status) {
    case 'active':
    case 'ok':
      return <AppBadge text="OK" icon="check" />;
    case 'locked':
      return <AppBadge text="Locked" icon="lock" type="danger" />;
    case 'stolen':
      return <AppBadge text="Stolen" icon="ban" type="danger" />;
    case 'inactive':
      return <AppBadge text="Inactive" icon="ban" type="danger" />;
    case 'expired':
      return <AppBadge text="Expired" icon="ban" type="danger" />;
    case 'destroyed':
      return <AppBadge text="Destroyed" icon="ban" type="danger" />;
    case 'restricted':
      return <AppBadge text="Restricted" icon="ban" type="danger" />;
    case 'voided':
      return <AppBadge text="Voided" icon="ban" type="danger" />;
    case 'closed':
      return <AppBadge text="Closed" icon="ban" type="danger" />;
    case 'do_not_honour':
      return <AppBadge text="Do Not Honour" icon="ban" type="danger" />;
    case 'lost':
      return <AppBadge text="Lost" icon="ban" type="danger" />;
    case 'security_violation':
      return <AppBadge text="Security violation" icon="ban" type="danger" />;
    case 'refunded':
      return <AppBadge text="Refunded" icon="ban" type="danger" />;
    case 'pending_activation':
      return <AppBadge text="Pending activation" icon="clock-o" type="info" />;
    case 'cardholder_to_contact_issuer':
      return (
        <AppBadge
          text="Cardholder to contact issuer"
          icon="clock-o"
          type="info"
        />
      );
    case 'pending_initialisation':
      return (
        <AppBadge text="Pending initialisation" icon="clock-o" type="warning" />
      );
    default:
      return (
        <AppBadge
          text={toHumanString(status)}
          icon={
            ['restrict', 'closed', 'void'].some((s) => status.indexOf(s) !== -1)
              ? 'ban'
              : 'clock-o'
          }
          type={
            ['restrict', 'closed', 'void'].some((s) => status.indexOf(s) !== -1)
              ? 'danger'
              : 'info'
          }
        />
      );
  }
};

export default CardStatusLabel;
