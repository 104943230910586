import React from 'react';
import RenderIfAllowed from '@launchpad/util/access-control/access-control.component';
import { currency } from '@launchpad/util/NumberHelper';
import OverrideService from '@launchpad/logic/services/overrides/OverrideService';
import ChangeForcedAvailableLimit from './ChangeForcedAvailableLimit';

const ForcedAvailableLimit = (props) => {
  const showForcedAvailableLimit =
    OverrideService.getConfig().flags?.showForcedAvailableLimit;

  if (!showForcedAvailableLimit) {
    return null;
  }

  const { onRefresh, account } = props;
  return (
    <>
      <dt>Forced available limit:</dt>
      <dd>
        <RenderIfAllowed action="Admin:LimitControl:Manage:*">
          <ChangeForcedAvailableLimit
            account={account}
            onUpdate={() => onRefresh()}
          />{' '}
        </RenderIfAllowed>
        {account.forcedAvailableLimit === null ||
        account.forcedAvailableLimit === undefined
          ? '- not set -'
          : currency(account.forcedAvailableLimit, account.currency)}
      </dd>
    </>
  );
};

export default ForcedAvailableLimit;
