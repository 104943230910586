import { AppRoute } from '@launchpad';
import React from 'react';
import { Switch } from 'react-router-dom';
import Versioner from './screens/Versioner';
import VersionerDetails from './screens/VersionerDetails';

export default class VersionerNavigation extends React.PureComponent {
  render() {
    return (
      <Switch>
        <AppRoute path="/versions" component={Versioner} />
        <AppRoute path="/versions/:id" component={VersionerDetails} />
      </Switch>
    );
  }
}
