import EventsModule from '@launchpad/modules/events/EventsModule';
import NotesModule from '@launchpad/modules/notes/NotesModule';
import _ from 'lodash';
import VendorCallsLogsModule from '../../modules/vendor-calls-logs/VendorCallsLogsModule';
import AccountsModule from '../../modules/accounts/AccountsModule';
import AnalyticsModule from '../../modules/analytics/AnalyticsModule';
import CardsModule from '../../modules/cards/CardsModule';
import ChatModule from '../../modules/chat/ChatModule';
import ConfigurationModule from '../../modules/configuration/ConfigurationModule';
import ContentManagementModule from '../../modules/content-managament/ContentManagementModule';
import DashboardModule from '../../modules/dashboard/DashboardModule';
import DictionaryModule from '../../modules/dictionary/DictionaryModule';
import FxRatesModule from '../../modules/fx-rates/FxRatesModule';
import KycModule from '../../modules/kyc/KycModule';
import LegalModule from '../../modules/legal/LegalModule';
import MyAccountModule from '../../modules/my-account/MyAccountModule';
import NotificationsModule from '../../modules/push/NotificationsModule';
import ReportsModule from '../../modules/reports/ReportsModule';
import RewardsModule from '../../modules/reward/RewardsModule';
import ShopModule from '../../modules/shop/ShopModule';
import ToolsModule from '../../modules/tools/ToolsModule';
import TransactionsModule from '../../modules/transactions/TransactionsModule';
import UsersModule from '../../modules/users/UsersModule';
import { OverrideService } from '../services';
import PermissionService from '../services/PermissionService';
import BaseModule from './BaseModule';
import VersionerModule from '@launchpad/modules/lplite/versioner/VersionerModule';

export default class ModuleService {
  getAllModules() {
    return [
      new DashboardModule(),
      new KycModule(),
      new UsersModule(),
      new AccountsModule(),
      new CardsModule(),
      new FxRatesModule(),
      new TransactionsModule(),
      new ConfigurationModule(),
      new ContentManagementModule(),
      new AnalyticsModule(),
      new ReportsModule(),
      new LegalModule(),
      new NotificationsModule(),
      new ToolsModule(),
      new MyAccountModule(),
      new DictionaryModule(),
      new ShopModule(),
      new ChatModule(),
      new RewardsModule(),
      new EventsModule(),
      new NotesModule(),
      new VendorCallsLogsModule(),
      new VersionerModule()
    ];
  }

  /**
   * Get modules to display
   *
   * TODO: Enable passing some settings to the modules:
   * when true is passed, module is enabled but when object is passed
   * module is both enabled and configured by the passed object.
   */
  getModules() {
    const allModules = this.getAllModules();
    const enabledModules = OverrideService.getModules();
    let filteredModules: any[] = [];
    // filteredModules = enabledModules;
    if (OverrideService.getConfig().doNotMergeModules) {
      filteredModules = enabledModules;
    } else if (enabledModules && !_.isEmpty(enabledModules)) {
      filteredModules = enabledModules.filter(x => {
        return allModules.find(y => y.getName() === x.getName());
      });
    } else {
      filteredModules = allModules;
    }

    // TODO: Filter modules based on permission
    return filteredModules.filter((x: BaseModule) => {
      if (x.getPolicy()) {
        return PermissionService.hasPolicy(x.getPolicy());
      }
      return PermissionService.hasPermission(x.getPermission());
    });
  }

  /**
   * Get navigation
   */
  getNavigation() {
    let items: any[] = [];

    // console.log(this.getModules());

    this.getModules().forEach(x => {
      items = [...items, ...x.getNavigation()];
    });

    return items;
  }

  /**
   * Get routes
   *
   * @param currentUrl string Current rl
   */
  getRoutes(currentUrl = '') {
    const moduleRoutes: any[] = [];
    this.getModules().forEach((x: any) => {
      moduleRoutes.push(x.getRoutes(currentUrl));
    });

    return moduleRoutes;
  }
}
