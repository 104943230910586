import React, { useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useApplicationsDetails } from '../hooks/useApplicationsDetails';
import SwitchButton from '@launchpad/components/elements/shared/SwitchButton';
import BreadcrumbHelper from '@launchpad/util/BreadcrumbHelper';
import apiCall, { queryClient } from '../helpers/apiCall';
import { useMutation } from '@tanstack/react-query';
import { LoadingOverlay } from '@launchpad/components';
import ToastHelper from '@launchpad/util/ToastHelper';

const InfoItem = ({ label, value }: { label: string; value: string }) => (
  <div className="d-flex justify-content-between">
    <p className="font-weight-bold">{label}</p>
    <p>{value}</p>
  </div>
);

const VersionForm: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, error } = useApplicationsDetails(id);

  const { mutate: forceUpdateMutate } = useMutation({
    mutationFn: () =>
      apiCall(`/application-version/${id}`, 'PATCH', {
        ...data,
        forceUpdate: !data.forceUpdate
      }),
    onSuccess: mutationData => {
      queryClient.setQueriesData(['applications', { id }], mutationData);
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({
        queryKey: ['applications'],
        refetchType: 'none'
      });
    },
    onError: err => {
      ToastHelper.show(
        (err as any)?.message || 'Updating not possible',
        'error'
      );
    }
  });

  const { mutate: softMutate } = useMutation({
    mutationFn: () => {
      return apiCall(`/application-version/${id}`, 'PATCH', {
        ...data,
        softUpdate: !data.softUpdate
      });
    },
    onSuccess: mutationData => {
      // Invalidate the query to refetch the data
      queryClient.setQueriesData(['applications', { id }], mutationData);
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({
        queryKey: ['applications'],
        refetchType: 'none'
      });
    }
  });

  useEffect(() => {
    BreadcrumbHelper.setCrumbs([
      {
        label: 'Version Management',
        link: '/versions'
      }
    ]);
    BreadcrumbHelper.addCrumb('Version Details');
  }, []);

  if (isLoading) {
    return <LoadingOverlay />;
  }
  if (error) {
    return <div>Error fetching version details</div>;
  }

  return (
    <div className="main" style={{ maxWidth: 500 }}>
      <div className="dark-blur" />
      <div className="padded">
        <div className="col">
          <InfoItem label="Name" value={data.name} />
          <InfoItem label="Application ID" value={data.applicationId} />
          <InfoItem label="Platform" value={data.platform} />
          <InfoItem
            label="Created At"
            value={moment(data.createdAt).format('DD/MM/YYYY')}
          />
          <InfoItem
            label="Updated At"
            value={moment(data.updatedAt).format('DD/MM/YYYY')}
          />
          <div className="d-flex justify-content-between">
            <p className="font-weight-bold">Force Update:</p>
            <SwitchButton
              onChange={() => forceUpdateMutate()}
              isChecked={data.forceUpdate}
            />
          </div>
          <div className="d-flex justify-content-between">
            <p className="font-weight-bold">Soft Update:</p>
            <SwitchButton
              onChange={() => softMutate()}
              isChecked={data.softUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VersionForm;
