import React, { useEffect } from 'react';
import BreadcrumbHelper from '@launchpad/util/BreadcrumbHelper';
import VersionsTable from '../components/VersionsTable';

const Versioner: React.FC = () => {
  useEffect(() => {
    BreadcrumbHelper.setCrumbs([
      {
        label: 'Version Management'
      }
    ]);
  }, []);

  return (
    <div>
      <h1>Version Management</h1>
      <div className="version-container">
        <VersionsTable />
      </div>
    </div>
  );
};

export default Versioner;
