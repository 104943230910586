/* eslint-disable import/prefer-default-export */
import { useQuery } from '@tanstack/react-query';
import apiCall from '../helpers/apiCall';

export function useListQuery(page, limit = 10, url: string, queryKey: string) {
  return useQuery({
    queryKey: [queryKey, page],
    queryFn: async () => {
      const response: {
        success: boolean;
        data: any[];
        count: number;
        page: number;
        pageCount: number;
        total: number;
      } = await apiCall(`${url}?limit=${limit}&page=${page}&offset=0`);
      if (!response.data) {
        throw new Error('Network response was not ok');
      }
      return {
        items: response.data,
        pagination: {
          count: response.count,
          page: response.page,
          pageCount: response.pageCount,
          total: response.total
        }
      };
    },
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5
  });
}
