import React, { useState } from 'react';
import AppTablePagination from '@launchpad/components/crud/table/AppTablePagination';

const Table: React.FC<{
  columns: {
    label: string;
    key: string;
    className?: string;
  }[];
  query: any;
  renderDataRow: (item: any) => React.ReactNode;
  limit?: number;
}> = ({ columns, query, renderDataRow, limit = 10 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, error } = query(currentPage, limit);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading</div>;

  return (
    <div className="w-full">
      <table className="table">
        <tbody>
          <tr className="tr-dark-bg">
            {columns.map(column => (
              <td align="left" key={column.key} className={column.className}>
                {column.label}
              </td>
            ))}
          </tr>
          {data?.items?.map((item: any) => renderDataRow(item))}
        </tbody>
      </table>
      <div className="pagination">
        <AppTablePagination
          pagination={{
            page: data?.pagination.page,
            totalPages: data?.pagination.pageCount,
            totalItems: data?.pagination.total,
            limit: limit
          }}
          onPageChanged={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default Table;
