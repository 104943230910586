/* eslint-disable import/prefer-default-export */
import { useQuery } from '@tanstack/react-query';
import apiCall from '../helpers/apiCall';

export function useApplicationsDetails(id: string) {
  console.log('useApplicationsDetails', id);
  return useQuery({
    queryKey: ['applications', { id }],
    queryFn: () => apiCall(`/application-version/${id}`)
  });
}
