import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';
import VersionerNavigation from './VersionerNavigation';
import VersionerDetails from './screens/VersionerDetails';

export const NAME = 'versioner';

export default class VersionerModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'users.*';
  }

  getPolicy() {
    return 'Admin:User:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Versioner',
        icon: 'code',
        path: '/versions',
        permission: 'users.*',
        policy: 'Admin:User:View:*'
      }
    ];
  }

  getRoutes() {
    return [
      <AppRoute path="/versions" component={VersionerNavigation} />,
      <AppRoute path="/versioner/:id" component={VersionerDetails} />
    ];
  }
}
