import React from 'react';
import moment from 'moment';
import Table from './Table';
import { useApplicationsList } from '../hooks/useApplicationsList';
import { Link } from 'react-router-dom';

const VersionsTable: React.FC = () => {
  return (
    <div className="w-full">
      <Table
        query={useApplicationsList}
        columns={[
          {
            label: 'ID',
            key: 'id',
            className: 'text-nowrap'
          },
          {
            label: 'Name',
            key: 'name',
            className: 'text-nowrap'
          },
          {
            label: 'Application ID',
            key: 'applicationId',
            className: 'text-nowrap'
          },
          {
            label: 'Platform',
            key: 'platform',
            className: 'text-nowrap'
          },
          {
            label: 'Force Update',
            key: 'forceUpdate',
            className: 'text-nowrap'
          },
          {
            label: 'Soft Update',
            key: 'softUpdate',
            className: 'text-nowrap'
          },
          {
            label: 'Created At',
            key: 'createdAt',
            className: 'text-nowrap'
          },
          {
            label: 'Actions',
            key: 'actions',
            className: 'text-nowrap'
          }
        ]}
        renderDataRow={version => (
          <tr key={version.id}>
            <td>{version.id}</td>
            <td className="text-nowrap">{version.name}</td>
            <td>{version.applicationId}</td>
            <td className="text-nowrap">{version.platform}</td>
            <td className="text-nowrap">
              {version.forceUpdate ? 'Yes' : 'No'}
            </td>
            <td className="text-nowrap">{version.softUpdate ? 'Yes' : 'No'}</td>
            <td className="text-nowrap">
              {moment(version.createdAt).format('DD/MM/YYYY')}
            </td>
            <td>
              <Link className="button-info" to={`/versioner/${version.id}`}>
                Edit
              </Link>
            </td>
          </tr>
        )}
      />
    </div>
  );
};

export default VersionsTable;
